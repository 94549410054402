<template>
    <div class="app-container">
        <vs-tabs v-model="tabIndex">
            <vs-tab
                v-show="getmyMedicalDoctorbind.length != 0"
                v-for="(getmyMedicalDoctorbinds, index) in getmyMedicalDoctorbind"
                :label="getmyMedicalDoctorbinds.fullname"
                :name="'Personal_Shift-' + index"
                :ref="'Personal_Shift-' + index"
                :key="getmyMedicalDoctorbinds.did"
                @click="fetchShift(getmyMedicalDoctorbinds.did)"
            >
                <!-- 每診最大預約人數 -->
                <div class="flex items-center text-lg mb-base">
                    <span>{{ $t('shift.maxAppointment') }}:</span>
                    <el-input class="cd-appt-input mx-1 w-24" type="number" v-model="limit" @change="updatLimit"></el-input>
                    <span>{{ $t('shift.people') }}</span>
                </div>
                <!-- 固定班表 -->
                <div class="cd-form-group">
                    <span class="text-lg">{{ $t('shift.shiftSetting') }}</span>
                </div>
                <vs-row class="mb-base">
                    <vs-col vs-type="flex" vs-justify="center" vs-lg="6" vs-sm="6" vs-xs="12" v-for="(data, index) in fixedShiftList" :key="index">
                        <div class="cd-card-group">
                            <vs-card>
                                <vs-row class="cd-card">
                                    <vs-col class="cd-form-group" vs-type="flex" vs-justify="flex-start" vs-align="center">
                                        <span class="cd-p-normal">{{ weekText(data.week) }}</span>
                                    </vs-col>
                                    <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="center" v-for="(item, index) in data.interval" :key="index">
                                        <p class="cd-p-normal">{{ intervalText(item.id) }}{{ item.checked ? ' - ' + item.start_time + ' - ' + item.end_time : ' - 00:00 - 00:00' }}</p>
                                        <div class="flex items-center">
                                            <span class="cursor-pointer text-primary px-2 hover:underline hover:font-semibold" v-if="item.checked" @click="changeTime(data.week, item)">{{ $t('shift.changeTime') }}</span>
                                            <vs-switch class="mx-2" v-model="item.checked" @click="checkEffect_fixed(data.week, item)"></vs-switch>
                                        </div>
                                    </vs-col>
                                </vs-row>
                            </vs-card>
                        </div>
                    </vs-col>
                </vs-row>
                <!-- 班表 -->
                <vx-card class="pt-0">
                    <div class="flex justify-between items-center">
                        <span class="text-lg">{{ getmyMedicalDoctorbinds.fullname + '的班表' }}</span>
                        <div class="text-primary cursor-pointer hover:font-semibold flex items-center" @click="scroll">
                            <feather-icon class="mr-1" icon="SettingsIcon" svgClasses="h-4 w-4" />
                            <span>{{ $t('shift.ShiftSetting') }}</span>
                        </div>
                    </div>
                    <el-calendar class="cd-calendar-thead" v-model="calendarDate" v-if="shiftList && shiftList.length > 0">
                        <template slot="dateCell" slot-scope="{ data }">
                            <div :class="enableJudge(data)" style="margin: -8px; padding: 8px" @click="showLeaveDialog(data)">
                                <div class="text-center">
                                    <p style="font-size: 24px" v-show="windowWidth >= 512">{{ data.day.split('-').slice(2).join('-') }}</p>
                                    <p style="font-size: 10px" v-show="windowWidth < 512">{{ data.day.split('-').slice(2).join('-') }}</p>
                                </div>
                                <!-- 全院休診 -->
                                <vs-row class="mt-1 sm:mt-1" v-if="isHoilday(data)">
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                                        <p class="text-danger" :style="windowWidth >= 512 ? 'font-size: 15px;' : 'font-size: 7px;'">{{ $t('shift.closed') }}</p>
                                    </vs-col>
                                </vs-row>
                                <vs-row class="mt-1 sm:mt-1" v-else>
                                    <!-- 上午 -->
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
                                        <p :class="leaveJudge(data, 1) ? 'text-danger' : ''" :style="windowWidth >= 512 ? 'font-size: 15px;' : 'font-size: 7px;'">{{ printWork(data, 1) }}</p>
                                    </vs-col>
                                    <!-- 下午 -->
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
                                        <p :class="leaveJudge(data, 2) ? 'text-danger' : ''" :style="windowWidth >= 512 ? 'font-size: 15px;' : 'font-size: 7px;'">{{ printWork(data, 2) }}</p>
                                    </vs-col>
                                    <!-- 晚上 -->
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
                                        <p :class="leaveJudge(data, 3) ? 'text-danger' : ''" :style="windowWidth >= 512 ? 'font-size: 15px;' : 'font-size: 7px;'">{{ printWork(data, 3) }}</p>
                                    </vs-col>
                                </vs-row>
                            </div>
                        </template>
                    </el-calendar>
                    <!-- × 只能排休今天以後的日期(不含今天) -->
                    <vs-row>
                        <vs-col>
                            <h6 class="text-center" style="color: red">{{ $t('shift.shiftNote') }}</h6>
                        </vs-col>
                    </vs-row>
                </vx-card>
            </vs-tab>
        </vs-tabs>
        <!-- 調整上班時間視窗 -->
        <el-dialog :title="$t('shift.changeTime')" :visible.sync="timeDialogVisible" :width="windowWidth < 768 ? '90%' : '40%'" :before-close="handleClose" center>
            <vs-row>
                <vs-col class="mb-base" vs-type="flex" vs-justify="center" vs-lg="6" vs-sm="6" vs-xs="12">
                    <el-time-select class="mr-2" popper-class="cd-shift-select" :placeholder="$t('shift.startTime')" v-model="startTime" size="default" width="160px" @change="changeStartTime" :picker-options="startTimeOption"> </el-time-select>
                </vs-col>
                <vs-col class="mb-base" vs-type="flex" vs-justify="center" vs-lg="6" vs-sm="6" vs-xs="12">
                    <el-time-select popper-class="cd-shift-select" :placeholder="$t('shift.endTime')" v-model="endTime" size="default" width="160px" :picker-options="endTimeOption"> </el-time-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center">
                    <vs-button type="flat" class="cd-button-2w mr-8" @click="handleClose">{{ $t('popup.cancel') }}</vs-button>
                    <vs-button class="cd-button-2w" :disabled="!(startTime && endTime)" @click="updateShift(sweek, sinterval, startTime, endTime)">
                        {{ $t('popup.save') }}
                    </vs-button>
                </vs-col>
            </vs-row>
        </el-dialog>
        <!-- 請假視窗 -->
        <el-dialog :title="$t('shift.scheduledOff')" :visible.sync="leaveDialog" :width="windowWidth < 768 ? '60%' : '30%'">
            <vs-row v-if="sdate">
                <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-around" vs-align="center" v-for="(data, index) in sdate.interval" :key="index">
                    <span class="text-lg text-black">{{ intervalText(data.id) }}</span>
                    <el-switch :class="data.open ? 'cd-switch-primary' : 'cd-switch-danger'" v-model="data.open" active-color="#003f8e" inactive-color="#EA5455" active-text="開診" inactive-text="請假" @click.native="checkEffect_expt(sdate, data.id)"> </el-switch>
                </vs-col>
            </vs-row>
        </el-dialog>
    </div>
</template>

<script>
import { updatelimit, noticeDoctorShift, fetchShift, updateShift, updateLeave } from '@/api/user'

export default {
    async mounted() {
        await this.fetchShift(this.did)
        this.$store.dispatch('fetchMyDoctor', this.$store.getters.serviceUnit.hid) // 取 其他 已授權班表的人 的資料
    },
    data() {
        return {
            // 新版
            tabIndex: 0,
            calendarDate: new Date(),
            todayDate: new Date(),
            shiftList: [],
            fixedShiftList: [],
            sdid: 0,
            effectTotal: 0,
            sweek: 0,
            sinterval: 0,
            startTime: '',
            endTime: '',
            sdate: '',
            timeDialogVisible: false,
            limit: 0,
            leaveDialog: false,
            startTimeOption: '',
            endTimeOption: '',
            stime1: {
                start: '08:00',
                step: '00:30',
                end: '13:00',
            },
            stime2: {
                start: '13:00',
                step: '00:30',
                end: '18:00',
            },
            stime3: {
                start: '18:00',
                step: '00:30',
                end: '24:00',
            },
            etime1: {
                start: '08:00',
                step: '00:30',
                end: '13:00',
                minTime: '',
            },
            etime2: {
                start: '13:00',
                step: '00:30',
                end: '18:00',
                minTime: '',
            },
            etime3: {
                start: '18:00',
                step: '00:30',
                end: '24:00',
                minTime: '',
            },
        }
    },
    watch: {
        // 點擊日期
        async calendarDate(n, o) {
            if (n.getMonth() + 1 != o.getMonth() + 1) {
                await this.fetchShift(this.sdid)
            }
        },
    },
    methods: {
        // 回傳 星期 文字
        weekText(week) {
            var statusMap = {
                1: this.$t('shift.mon'),
                2: this.$t('shift.tue'),
                3: this.$t('shift.wed'),
                4: this.$t('shift.thu'),
                5: this.$t('shift.fri'),
                6: this.$t('shift.sat'),
                7: this.$t('shift.sun'),
            }
            return statusMap[week]
        },
        // 回傳 時段 文字
        intervalText(week) {
            var statusMap = {
                1: this.$t('shift.am'),
                2: this.$t('shift.pm'),
                3: this.$t('shift.night'),
            }
            return statusMap[week]
        },
        // 更新 每診最大預約人數
        updatLimit() {
            if (!this.limit || this.limit < 0) {
                this.notify('danger', '請輸入正確的每診最大預約人數')
                return
            }
            const payload = {
                sdid: this.sdid,
                limit: parseInt(this.limit),
            }
            updatelimit(payload)
        },
        // 開啟 變更時間 視窗
        changeTime(week, item) {
            this.sweek = week
            this.sinterval = item.id
            this.startTime = item.start_time
            this.endTime = item.end_time
            if (this.sinterval == '1') {
                this.startTimeOption = this.stime1
            } else if (this.sinterval == '2') {
                this.startTimeOption = this.stime2
            } else if (this.sinterval == '3') {
                this.startTimeOption = this.stime3
            }
            this.changeStartTime()
            this.timeDialogVisible = true
        },
        // 變更開始時間時，讓結束時間只能選擇開始時間之後的時間
        changeStartTime() {
            if (this.sinterval == '1') {
                this.etime1.minTime = this.startTime
                this.endTimeOption = this.etime1
            } else if (this.sinterval == '2') {
                this.etime2.minTime = this.startTime
                this.endTimeOption = this.etime2
            } else if (this.sinterval == '3') {
                this.etime3.minTime = this.startTime
                this.endTimeOption = this.etime3
            }
        },
        // 關閉 變更時間 視窗
        handleClose() {
            this.timeDialogVisible = false
        },
        // 取醫師班表 (新版)
        fetchShift(sdid) {
            this.sdid = sdid
            let payload = {
                sdid: sdid,
                partner_id: this.partnerid,
                year: this.calendarDate.getFullYear(),
                month: this.calendarDate.getMonth() + 1,
            }
            fetchShift(payload).then((res) => {
                this.limit = res.data.limit
                this.shiftList = []
                this.shiftList = _.cloneDeep(res.data.shift)
                this.fixedShiftList = [
                    {
                        week: 1,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                    {
                        week: 2,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                    {
                        week: 3,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                    {
                        week: 4,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                    {
                        week: 5,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                    {
                        week: 6,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                    {
                        week: 7,
                        interval: [
                            { id: 1, checked: false },
                            { id: 2, checked: false },
                            { id: 3, checked: false },
                        ],
                    },
                ]
                this.fixedShiftList.forEach(function (v, i) {
                    var temp
                    if ((temp = _.find(res.data.fixedShift, { week: v.week }))) {
                        for (var k = 1; k <= 3; k++) {
                            if (_.find(temp.interval, { id: k })) v.interval[k - 1] = _.find(temp.interval, { id: k })
                        }
                    }
                })
            })
        },
        // 判斷是否為全院休診
        isHoilday(data) {
            if (data.type != 'current-month') return false
            if (!_.find(this.shiftList, { date: data.day })) return false
            return _.find(this.shiftList, { date: data.day }).holiday
        },
        // 取各時段的班表
        printWork(data, i) {
            if (data.type != 'current-month') return '　'
            if (!_.find(this.shiftList, { date: data.day })) return '　'
            var intervalList = _.find(this.shiftList, { date: data.day }).interval
            if (!_.find(intervalList, { id: i })) return '　'
            var eachInterval = _.find(intervalList, { id: i }).open
            if (eachInterval == true) {
                if (i == 1) {
                    return this.windowWidth >= 512 ? this.$t('shift.am') : this.$t('shift.amRWD')
                } else if (i == 2) {
                    return this.windowWidth >= 512 ? this.$t('shift.pm') : this.$t('shift.pmRWD')
                } else if (i == 3) {
                    return this.windowWidth >= 512 ? this.$t('shift.night') : this.$t('shift.nightRWD')
                }
            } else {
                return this.windowWidth >= 512 ? '請假' : '假'
            }
        },
        // 判斷該時段是否請假
        leaveJudge(data, i) {
            if (data.type != 'current-month') return false
            if (data.day < this.$moment(this.todayDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) return false
            if (!_.find(this.shiftList, { date: data.day })) return false
            var intervalList = _.find(this.shiftList, { date: data.day }).interval
            if (!_.find(intervalList, { id: i })) return false
            var eachInterval = _.find(intervalList, { id: i }).open
            if (eachInterval == false) return true
        },
        // 判斷該日期class
        enableJudge(data) {
            if (data.type != 'current-month') return ''
            if (data.day < this.$moment(this.todayDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) return 'cd-shift-grey'
            // if(this.isHoilday(data) == true) return ''
            // if( !_.get(_.find(this.shiftList,{'date': data.day}), 'interval', false) ) return ''
            if (data.day == this.$moment(this.todayDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) {
                return 'text-primary border border-solid border-primary border-t-4 border-b-0 border-r-0 border-l-0'
            } else {
                return ''
            }
        },
        // 檢查修改班表是否有影響到已掛號的民眾，如果有則發送通知警告
        async checkEffect_fixed(week, item) {
            // 開班表不用檢查，直接更新
            if (item.checked == false) {
                this.updateShift(week, item.id)
            } else {
                // 檢查修改班表是否有影響到已掛號的民眾
                await noticeDoctorShift({ type: 0, interval: item.id, sdid: this.sdid, week: week }).then((res) => {
                    this.effectTotal = res.data.total
                })
                // 如果有影響，開啟警告視窗
                if (this.effectTotal > 0) {
                    this.$confirm('此次班表更動將會影響' + this.effectTotal + '診掛號，您將需要自行通知已掛號民眾做取消掛號的動作，是否仍要更動班表？', this.$t('popup.alert'), {
                        confirmButtonText: this.$t('popup.confirm'),
                        cancelButtonText: this.$t('popup.cancel'),
                        type: 'warning',
                    })
                        .then(() => {
                            this.updateShift(week, item.id)
                        })
                        .catch(() => {
                            item.checked = !item.checked
                        })
                } else {
                    this.updateShift(week, item.id)
                }
            }
        },
        // 更新班表
        async updateShift(week, interval, newStart = null, newEnd = null) {
            let payload = {
                sdid: this.sdid,
                partner_id: this.partnerid,
                week: week,
                interval: interval,
            }
            if (newStart) payload['startTime'] = newStart
            if (newEnd) payload['endTime'] = newEnd
            await updateShift(payload)
            await this.fetchShift(this.sdid)
            if (this.timeDialogVisible == true) this.timeDialogVisible = false
        },
        // 打開請假視窗
        showLeaveDialog(data) {
            if (data.type != 'current-month') return false
            if (data.day <= this.$moment(this.todayDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) return false
            if (this.isHoilday(data) == true) return false
            if (!_.get(_.find(this.shiftList, { date: data.day }), 'interval', false)) return false
            this.sdate = _.find(this.shiftList, { date: data.day })
            this.leaveDialog = true
        },
        // 檢查修改班表是否有影響到已掛號的民眾
        async checkEffect_expt(data, interval) {
            let index = _.findIndex(data.interval, { id: interval })
            // 開診不用檢查，直接更新
            if (data.interval[index].open) {
                this.dayOff(data, interval)
                return
            }
            // 檢查修改班表是否有影響到已掛號的民眾
            await noticeDoctorShift({ type: 1, interval: interval, sdid: this.sdid, date: data.date }).then((res) => {
                this.effectTotal = res.data.total
            })
            // 如果有影響，開啟警告視窗
            if (this.effectTotal > 0) {
                this.$confirm('此次班表更動將會影響' + this.effectTotal + '診掛號，您將需要自行通知已掛號民眾做取消掛號的動作，是否仍要更動班表？', this.$t('popup.alert'), {
                    confirmButtonText: this.$t('popup.confirm'),
                    cancelButtonText: this.$t('popup.cancel'),
                    type: 'warning',
                })
                    .then(() => {
                        this.dayOff(data, interval)
                    })
                    .catch(() => {
                        this.sdate.interval[interval - 1].open = !this.sdate.interval[interval - 1].open
                    })
            } else {
                this.dayOff(data, interval)
            }
        },
        // 請假
        async dayOff(data, interval) {
            let payload = {
                sdid: this.sdid,
                partner_id: this.partnerid,
                change_date: data.date,
                interval: interval,
            }
            await updateLeave(payload).then((res) => {
                this.notify('grey', res.data.msg)
            })
            await this.fetchShift(this.sdid)
        },
        // 通知
        notify(color, title, text = '') {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
        scroll() {
            window.scrollTo(0, 140)
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        getmyMedicalDoctorbind: {
            get() {
                let me = {
                    did: this.did,
                    fullname: '我',
                }
                let rs = _.cloneDeep(this.$store.getters.chosenMedicaldoctor)
                rs.unshift(me)
                return rs
            },
        },
        partnerid() {
            return this.$store.getters.partner
        },
        did() {
            return this.$store.getters.did
        },
        dname() {
            return this.$store.getters.name
        },
    },
}
</script>
<style>
.cd-shift-grey {
    color: #c0c4cc !important;
}
p.tagStyle {
    font-size: 15px;
}
p.tagStyleMobile {
    font-size: 7px;
}
.el-calendar__header .el-calendar__title {
    position: absolute;
    left: 50%;
    transform: translate(-45%);
    font-size: 20px;
    color: black;
}
.el-calendar__button-group {
    width: 100%;
}
.el-button-group {
    display: flex;
    justify-content: space-between;
}
.el-button-group::after,
.el-button-group::before {
    content: unset;
}
/* 用於判斷要不要顯示今日的按鈕 */
.el-button-group > .el-button:not(:first-child):not(:last-child) {
    display: none;
}
/* 上個月按鈕的樣式 */
.el-button-group > .el-button:first-child {
    background-color: white;
    border: none;
    border-radius: 15%;
}
/* 把上個月按鈕替換為< */
.el-button-group > .el-button:first-child:before {
    content: '<';
    color: black;
    font-size: 20px;
}
/* 下個月按鈕的樣式 */
.el-button-group > .el-button:last-child {
    background-color: white;
    border: none;
    border-radius: 15%;
}
/* 把下個月按鈕替換為> */
.el-button-group > .el-button:last-child:before {
    content: '>';
    color: black;
    font-size: 20px;
}
.el-button-group > .el-button:first-child span,
.el-button-group > .el-button:last-child span {
    display: none;
}
.cd-calendar-thead th {
    text-align: center !important;
    font-size: 1rem;
}
.cd-shift-select {
    z-index: 52003 !important;
}
</style>
